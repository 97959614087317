<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("organizationalStructure.company")
                }}</label>
                <select
                    v-model.number.trim="model.company_id"
                    class="form-select"
                    :class="{ 'is-invalid': errors.has('company_id') }"
                    ref="company_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(company, index) in companies"
                        :key="index"
                        :value="company.company_id"
                        >{{ company.company_name_en }}</option
                    >
                </select>
                <div class="invalid-feedback" v-if="errors.has('company_id')">
                    {{ errors.first("company_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("organizationalStructure.structureName")
                }}</label>
                <input
                    v-model.trim="model.org_chart_structure"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('org_chart_structure') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('org_chart_structure')"
                >
                    {{ errors.first("org_chart_structure") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("organizationalStructure.levelCounter")
                }}</label>
                <select
                    v-model.trim.number="model.level_counter"
                    class="form-select"
                    :class="{ 'is-invalid': errors.has('level_counter') }"
                    ref="level_counter"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(levelCounter, index) in levelCounters"
                        :key="index"
                        :value="levelCounter.level_counter"
                        >{{ levelCounter.level_description }}</option
                    >
                </select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('level_counter')"
                >
                    {{ errors.first("level_counter") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label ">{{
                    $t("organizationalStructure.parentStructure")
                }}</label>
                <select
                    v-model.number.trim="model.parent_org_chart_structure_id"
                    class="form-select"
                    :class="{
                        'is-invalid': errors.has(
                            'parent_org_chart_structure_id'
                        )
                    }"
                    ref="parent_org_chart_structure_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(parentStructure,
                        index) in parentStructuresFilter"
                        :key="index"
                        :value="parentStructure.org_chart_structure_id"
                        >{{ parentStructure.org_chart_structure }}</option
                    >
                </select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('parent_org_chart_structure_id')"
                >
                    {{ errors.first("parent_org_chart_structure_id") }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty, includes } from "lodash";

export default {
    name: "organizational-structureForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                company_id: null,
                org_chart_structure: null,
                level_counter: null,
                parent_org_chart_structure_id: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/organizationalStructure", [
            "edit_data",
            "companies",
            "levelCounters",
            "parentStructures"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        parentStructuresFilter() {
            return this.parentStructures.filter(
                i =>
                    !includes(
                        [this.edit_data.org_chart_structure_id],
                        i.org_chart_structure_id
                    )
            );
        }
    },
    methods: {
        ...mapActions("humanResource/organizationalStructure", [
            "getCompany",
            "getLevelCounter",
            "getParentStructure"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getCompany();
            await this.getLevelCounter();
            await this.getParentStructure();
            this.loading = false;
        },

        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch(
                    "humanResource/organizationalStructure/store",
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.fetchResource();
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "humanResource/organizationalStructure/store",
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/organizationalStructure/update", {
                    id: this.edit_data.org_chart_structure_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.company_id.focus();
            });
            this.fetchResource();
            if (!isEmpty(this.edit_data)) {
                this.model.company_id = this.edit_data.company_id;
                this.model.org_chart_structure = this.edit_data.org_chart_structure;
                this.model.level_counter = this.edit_data.level_counter;
                this.model.parent_org_chart_structure_id = this.edit_data.parent_org_chart_structure_id;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.company_id = null;
            this.model.org_chart_structure = null;
            this.model.level_counter = null;
            this.model.parent_org_chart_structure_id = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "ORGANIZATIONAL STRUCTURE",
                desc: not.text
            });
        }
    }
};
</script>
